import React from "react";
import PropTypes from "prop-types";

import {
  DevelopmentName,
  AddressLine1,
  Postcode,
  PropertySize,
} from "../../../formGroups/PropertyDetailsGroup";

export default function PropertyDetails({ control, register, getValues }) {
  return (
    <>
      <DevelopmentName control={control} required={true} register={register} />
      <AddressLine1 control={control} required={true} register={register} />
      <Postcode
        control={control}
        required={true}
        getValues={getValues}
        register={register}
        label="Enter Postcode"
      />
      <PropertySize control={control} required={true} register={register} />
    </>
  );
}

// PropTypes
PropertyDetails.propTypes = {
  control: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  getValues: PropTypes.func,
};
